<template>
    <div>
      <CCard>
        <CCardHeader>
          {{ $t('evaluationTitle') }}
        </CCardHeader>
        <CCardBody>
          <div class="my-5 d-flex flex-column align-items-center justify-content-center" v-if="loadingEvaluation || !evaluationData">
            <div class="spinner-border text-primary mb-3" style="width: 70px; height: 70px;" role="status">
            </div>
            <p class="mb-0 text-primary">{{ $t('loadingEvaluation') }}</p>
          </div>
          <CTabs variant="pills" :vertical="{ navs: 'col-sm-3', content: 'col-sm-9' }" v-else>
            <CTab active>
              <template slot="title">
                <CIcon name="cil-user" class="mr-2"/> {{ $t('evaluatedUserData') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2"> {{ $t('evaluatedUserData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('name') }}: </b> {{evaluationData.evaluado.nombre}} {{evaluationData.evaluado.apellido}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('gender') }}: </b> {{evaluationData.evaluado.sexo}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('email') }}: </b> {{evaluationData.evaluado.email}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('phone') }}: </b> {{evaluationData.evaluado.telefono}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('region') }}: </b> {{evaluationData.evaluado.region}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('comuna') }}: </b> {{evaluationData.evaluado.comuna}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('address') }}: </b> {{evaluationData.evaluado.direccion}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('birth') }}: </b> {{evaluationData.evaluado.nacimiento}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('age') }}: </b> {{evaluationData.evaluado.nacimiento | age}} {{ $t('years') }}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-4" v-if="evaluationData.clinical_data">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('evaluatedUserClinicalData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('temperature') }}: </b> {{evaluationData.clinical_data.temperatura}} ºC
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('dificulToBreath') }}: </b> {{evaluationData.clinical_data.dificultad_respirar ? $t('yes'):$t('no') }}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-4" v-if="evaluationData.clinical_data">
                <CCol xs="12" lg="6">
                  <div v-if="evaluationData.clinical_data.ecnt">
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('entDisease') }}</b>
                    </p>
                    <p class="text-center text-primary mb-1 d-flex align-items-center justify-content-center px-2 py-1"
                      v-if="evaluationData.clinical_data.ecnt.every((item) => { return item.value != true })">
                      <b>{{ $t('noEntDisease') }}</b>
                    </p>
                    <div v-else>
                      <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1"
                        :class="index%2 != 0 ? 'bg-secondary':''"
                        v-for="(ent, index) in evaluationData.clinical_data.ecnt.filter((item) => {return item.value == true})"
                        :key="index">
                        <b class="text-primary">{{ $t(ent.name) }}: </b> {{ent.tratamiento ? $t('inTreatment'):$t('noTreatment') }}
                      </p>
                    </div>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div v-if="evaluationData.clinical_data.autoinmune">
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('autoinmuneDisease') }}</b>
                    </p>
                    <p class="text-center text-primary mb-1 d-flex align-items-center justify-content-center px-2 py-1"
                      v-if="evaluationData.clinical_data.autoinmune.every((item) => { return item.value != true })">
                      <b>{{ $t('noAutoinmuneDisease') }}</b>
                    </p>
                    <div v-else>
                      <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1"
                        :class="index%2 != 0 ? 'bg-secondary':''"
                        v-for="(ent, index) in evaluationData.clinical_data.autoinmune.filter((item) => {return item.value == true})"
                        :key="index">
                        <b class="text-primary">{{ $t(ent.name) }}: </b> {{ent.tratamiento ? $t('inTreatment'):$t('noTreatment') }}
                      </p>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-list"/> {{ $t('entryData') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('basicData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('cronAge') }}: </b> {{evaluationData.evaluacion.ecrono}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('gender') }}: </b> {{evaluationData.evaluado.sexo}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('height') }}: </b> {{evaluationData.evaluacion.estatura}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('weight') }}: </b> {{evaluationData.evaluacion.peso}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('alcohol') }}: </b> {{evaluationData.evaluacion.alcohol}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('smoke') }}: </b> {{evaluationData.evaluacion.fuma}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('neckPerimeter') }}: </b> {{evaluationData.evaluacion.cuello}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('waistPerimeter') }}: </b> {{evaluationData.evaluacion.pcintura}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('hipPerimeter') }}: </b> {{evaluationData.evaluacion.pcadera}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('rightArmPerimeter') }}: </b> {{evaluationData.evaluacion.pbrazo}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('rightLegPerimeter') }}: </b> {{evaluationData.evaluacion.ppierna}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('diastolic') }}: </b> {{evaluationData.evaluacion.presion_d}} mmHg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('sistolic') }}: </b> {{evaluationData.evaluacion.presion_s}} mmHg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary" v-if="evaluationData.evaluacion.triceps">
                      <b class="text-primary">{{ $t('pTriceps') }}: </b> {{evaluationData.evaluacion.triceps}} mm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1" v-if="evaluationData.evaluacion.subescapular">
                      <b class="text-primary">{{ $t('pSubescapular') }}: </b> {{evaluationData.evaluacion.subescapular}} mm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary" v-if="evaluationData.evaluacion.abdominal">
                      <b class="text-primary">{{ $t('pAbdominal') }}: </b> {{evaluationData.evaluacion.abdominal}} mm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1" v-if="evaluationData.evaluacion.cuadriceps">
                      <b class="text-primary">{{ $t('pCuadriceps') }}: </b> {{evaluationData.evaluacion.cuadriceps}} mm.
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('fisiologicalData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imc') }}: </b> {{evaluationData.evaluacion.imc | twoDecimal}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('fatPercent') }}: </b> {{evaluationData.evaluacion.pgrasa | twoDecimal}}%
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imb') }}: </b> {{evaluationData.evaluacion.imb | twoDecimal}} {{$t('calories')}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('magraPercent') }}: </b> {{parseFloat(evaluationData.evaluacion.masamuscular) + parseFloat(evaluationData.evaluacion.pmresidual) | twoDecimal}}%
                    </p>
                  </div>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-calculator"/> {{ $t('bodyComposition') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('bodyCompositionAnalysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fatPercent') }}: </b> {{evaluationData.evaluacion.pgrasa | twoDecimal}}%
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('magraPercent') }}: </b> {{parseFloat(evaluationData.evaluacion.masamuscular) + parseFloat(evaluationData.evaluacion.pmresidual) | twoDecimal}}%
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fatWeight') }}: </b> {{evaluationData.evaluacion.pgraso | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('magroWeight') }}: </b> {{parseFloat(evaluationData.evaluacion.pmuscular) + parseFloat(evaluationData.evaluacion.mresidual) | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fullWeight') }}: </b> {{evaluationData.evaluacion.peso | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('imb') }}: </b> {{evaluationData.evaluacion.imb | twoDecimal}} {{ $t('calories') }}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imc') }}: </b> {{evaluationData.evaluacion.imc | twoDecimal}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <Doughnut :labels="[$t('graphFat'), $t('graphLean')]"
                      :datasets="[{
                        backgroundColor: [
                          '#41AB35',
                          '#3A2151',
                        ],
                        data: [evaluationData.evaluacion.pgrasa, parseFloat(evaluationData.evaluacion.masamuscular) + parseFloat(evaluationData.evaluacion.pmresidual)]
                      }]"/>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('imcAnalysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imc') }}: </b> {{evaluationData.evaluacion.imc}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imcIdeal') }}: </b> {{evaluationData.kids.ideal_imc}}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" lg="12">
                  <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                    <b>{{ $t('refImcRange') }}</b>
                  </p>
                  <CRow :gutters="false">
                    <CCol xs="2" lg="2">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('age') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.imc_index.table.edad | monthsToAge}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" class="bg-secondary" :class="evaluationData.kids.imc_index.index == 0 ? 'bg-danger':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('imc') }} {{ $t('veryLow') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{'< ' + evaluationData.kids.imc_index.table[1]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" :class="evaluationData.kids.imc_index.index == 1 ? 'bg-warning':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('imc') }} {{ $t('low') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.imc_index.table[1]}} - {{evaluationData.kids.imc_index.table[2]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" class="bg-secondary" :class="evaluationData.kids.imc_index.index == 2 ? 'bg-success':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('imc') }} {{ $t('normal') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.imc_index.table[2]}} - {{evaluationData.kids.imc_index.table[3]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" :class="evaluationData.kids.imc_index.index == 3 ? 'bg-warning':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('imc') }} {{ $t('high') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.imc_index.table[3]}} - {{evaluationData.kids.imc_index.table[4]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" class="bg-secondary" :class="evaluationData.kids.imc_index.index == 4 ? 'bg-danger':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('imc') }} {{ $t('veryHigh') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.imc_index.table[4] + ' >'}}</b></small></p>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('waistHipAnalysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="12">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-around px-2 py-1">
                      <b class="text-primary">{{ $t('waistHipIndex') }}: </b> {{evaluationData.kids.waist_relation | twoDecimal}}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" lg="12">
                  <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                    <b>{{ $t('referenceRange') }}</b>
                  </p>
                  <CRow :gutters="false">
                    <CCol xs="2" lg="2">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('to') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.waist_relation_reference.edad}} {{ $t('years') }}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="3" class="bg-secondary" :class="evaluationData.kids.waist_relation <= evaluationData.kids.waist_relation_reference[1] ? 'bg-warning':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('low') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{$t('to') + ' ' + evaluationData.kids.waist_relation_reference[1]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" :class="evaluationData.kids.waist_relation >= evaluationData.kids.waist_relation_reference[1] && evaluationData.kids.waist_relation < evaluationData.kids.waist_relation_reference[2] ? 'bg-success':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('moderate') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.waist_relation_reference[1]}} - {{evaluationData.kids.waist_relation_reference[2]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="2" class="bg-secondary" :class="evaluationData.kids.waist_relation >= evaluationData.kids.waist_relation_reference[2] && evaluationData.kids.waist_relation < evaluationData.kids.waist_relation_reference[3] ? 'bg-warning':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('high') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{evaluationData.kids.waist_relation_reference[2]}} - {{evaluationData.kids.waist_relation_reference[3]}}</b></small></p>
                    </CCol>
                    <CCol xs="2" lg="3" :class="evaluationData.kids.waist_relation >= evaluationData.kids.waist_relation_reference[3] ? 'bg-danger':''">
                      <p class="text-center text-white mb-0 d-flex align-items-center justify-content-center px-2 py-1 bg-primary"><small>{{ $t('veryHigh') }}</small></p>
                      <p class="text-center mb-0 text-primary py-1"><small><b>{{$t('from') + ' ' + evaluationData.kids.waist_relation_reference[3]}}</b></small></p>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('waistHeightAnalysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('waistHeightIndex') }}: </b> {{evaluationData.kids.waist_height.indice | twoDecimal}}
                    </p>
										<CRow>
											<CCol xs="4" lg="4">
												<div class="d-flex flex-row " style="font-size: 12px;">
													{{ $t('minorTo') }} 0.50
													<div class="bg-success rounded-lg color-indicator ml-2"></div>
												</div>
											</CCol>
											<CCol xs="4" lg="4">
												<div class="d-flex flex-row " style="font-size: 12px;">
													{{ $t('equalTo') }} 0.50
													<div class="bg-warning rounded-lg color-indicator ml-2"></div>
												</div>
											</CCol>
											<CCol xs="4" lg="4">
												<div class="d-flex flex-row " style="font-size: 12px;">
													{{ $t('mayorTo') }} 0.50
													<div class="bg-danger rounded-lg color-indicator ml-2"></div>
												</div>
											</CCol>
										</CRow>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('referenceRange') }}</b>
                    </p>
                    <p class="mb-0 d-flex align-items-center justify-content-between px-2 py-1"
                      v-for="(value, index) in waistHeight" :key="index" :class="setClassForWaisHeight(index, evaluationData.kids.waist_height.value)">
                      <b class="text-primary">{{value}}</b> {{index + 1}}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('fatPercent') }} {{ $t('analysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fatPercent') }} : </b> {{evaluationData.evaluacion.pgrasa | twoDecimal}}%
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('referenceRange') }}</b>
                    </p>
                    <p class="mb-0 d-flex align-items-center justify-content-between px-2 py-1"
                      v-for="(value, index) in referencePGrasa" :key="index" :class="setClassForPGrasa(index, evaluationData.evaluacion.pgrasa)">
                      <b class="text-primary">{{value.value}}</b> {{value.reference}}
                    </p>
                  </div>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-task"/> {{ $t('scoring') }}
              </template>
              <CRow class="no-gutters">
                <CCol xs="12" lg="12">
                  <CRow>
                    <CCol xs="12" lg="12">
                      <h5 class="bg-primary text-white text-center py-2">{{ $t('caloricRequirement') }}</h5>
                      <div>
                        <div class="d-flex align-items-center justify-content-center px-2 py-1">
													<div style="flex: 1">
                          	<p class="mb-0 text-center"><b>{{ $t('rcaloricoa') }}</b></p>
														<p class="mb-0 text-center" style="flex: 1">{{evaluationData.calculations.rcalorico[0] | twoDecimal}}</p>
													</div>
													<div style="flex: 1">
                          	<p class="mb-0 text-center"><b>{{ $t('rcaloricob') }}</b></p>
														<p class="mb-0 text-center" style="flex: 1">{{evaluationData.calculations.rcalorico[1] | twoDecimal}}</p>
													</div>
													<div style="flex: 1">
                          	<p class="mb-0 text-center"><b>{{ $t('rcaloricoc') }}</b></p>
														<p class="mb-0 text-center" style="flex: 1">{{evaluationData.calculations.rcalorico[2] | twoDecimal}}</p>
													</div>
													<div style="flex: 1">
                          	<p class="mb-0 text-center"><b>{{ $t('rcaloricod') }}</b></p>
														<p class="mb-0 text-center" style="flex: 1">{{evaluationData.calculations.rcalorico[3] | twoDecimal}}</p>
													</div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow class="mt-3">
                    <CCol xs="12" lg="12">
                      <h5 class="bg-primary text-white text-center py-2">{{ $t('scoring') }}: {{evaluationData.evaluacion.scoring | twoDecimal}}</h5>
                      <div>
                        <div class="d-flex align-items-center justify-content-center bg-dark px-2 py-1">
                          <p class="text-white mb-0" style="flex: 1"><b>{{ $t('entryLevel') }}</b></p>
                          <p class="text-white mb-0 text-center" style="flex: 1"><b>{{ $t('scoring') }}</b></p>
                          <p class="text-white mb-0" style="flex: 1"><b>{{ $t('trainingLevel') }}</b></p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom"
                            v-for="(scoring, index) in referenceScoring" :key="index"
                            :class="setClassForScoring(index, evaluationData.evaluacion.scoring)"
                            >
                          <p class="mb-1 d-flex align-items-center px-2 py-1" style="flex: 1">
                            {{scoring.nentrada}}
                          </p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">
                            {{scoring.value}}
                          </p>
                          <p class="mb-1 d-flex align-items-center px-2 py-1" style="flex: 1">
                            {{scoring.nentreno}}
                          </p>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow class="mt-3">
                    <CCol xs="12" lg="12">
											<h5 class="bg-primary text-white text-center py-2">{{ $t('metabolicgastoTraining') }}</h5>
											<div class="d-flex align-items-center mb-3">
												<h5 class="py-2 mb-0 mr-3">{{ $t('trainingTime') }}</h5>
												<v-select
													:placeholder="$t('trainingFrequencyQuestion')"
													:options="[{label: '30 ' + $t('minutes'), value: 30}, {label: '40 ' + $t('minutes'), value: 40}, {label: '60 ' + $t('minutes'), value: 60}]"
													:clearable="false"
													required
													:reduce="data => data.value"
													v-model="selTime"></v-select>
											</div>
                      <div>
                        <div class="d-flex align-items-center justify-content-center bg-dark px-2 py-1 text-center">
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('effortType') }}</b></p>
                          <p class="text-white justify-content-center mb-0 " style="flex: 1"><b>{{ $t('minimum') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('maximum') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('average') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('trainingVolumePercent') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('trainingVolumeMinutes') }}</b></p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom text-center">
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ $t('warmUp') }}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.6*0.9*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.6*1.1*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.6*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.scoringNentrenaRef.n1 }}%</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.selTime*this.scoringNentrenaRef.n1/100 }}</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom text-center">
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ $t('areobic') }}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.7*0.9*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.7*1.1*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.7*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.scoringNentrenaRef.n2 }}%</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.selTime*this.scoringNentrenaRef.n2/100 }}</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom text-center">
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ $t('increment') }}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.8*0.9*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.8*1.1*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.8*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.scoringNentrenaRef.n3 }}%</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.selTime*this.scoringNentrenaRef.n3/100 }}</p>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-history"/> {{ $t('historic') }}
              </template>
              <CRow class="no-gutters">
                <CCol sm="4">
									<div>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-primary text-white">{{ $t('date') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('imc') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('imb') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('fatWeight') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('magroWeight') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('fatPercent') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('magraPercent') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('waistHipIndex') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('waistHeightIndex') }}</p>
									</div>
                </CCol>
                <CCol sm="8">
									<CRow class="no-gutters">
										<CCol sm="4" v-for="(evaluation, index) in evaluationData.history" :key="index">
											<div class="text-right">
												<p class="mb-0 px-2 py-1 font-weight-bold bg-primary text-white"><small>{{evaluation.created_at}}</small></p>
												<p class="mb-0 px-2 py-1">{{evaluation.imc | twoDecimal}}</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.imb | twoDecimal}} {{ $t('calories') }}</p>
												<p class="mb-0 px-2 py-1">{{evaluation.pgraso | twoDecimal}} Kg.</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{parseFloat(evaluation.pmuscular) + parseFloat(evaluation.mresidual) | twoDecimal}} Kg.</p>
												<p class="mb-0 px-2 py-1">{{evaluation.pgrasa | twoDecimal}}%</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{parseFloat(evaluation.masamuscular) + parseFloat(evaluation.pmresidual) | twoDecimal}}%</p>
												<p class="mb-0 px-2 py-1">{{evaluation.kids.waist_relation | twoDecimal}}</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.kids.waist_height.indice | twoDecimal}}</p>
											</div>
										</CCol>
									</CRow>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-envelope-open"/> {{ $t('sendEvaluation') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <CRow>
                    <CCol xs="12" lg="12">
                      <h5 class="bg-primary text-white text-center py-2"> {{ $t('sendEvaluationByEmail') }}</h5>
                      <div>
                        <CInput
                          v-model="sendevaluationEmail"
                          :label="$t('email')"
                          :placeholder="$t('enterEmail')"
                          type="email"
                          horizontal
                          :isValid="needValidation ? required && email:undefined"
                        />
                        <CButton type="submit" color="primary" block @click="sendEvaluation" :disabled="loading"><CIcon name="cil-check-circle" /> {{ $t('sendEvaluation') }}</CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CTab>
          </CTabs>
        </CCardBody>
      </CCard>
    </div>
</template>

<script>
import moment from 'moment'
import {deleteUser} from '@/helpers/api/users'
import {getEvaluation, sendEvaluationByEmail} from '@/helpers/api/evaluations.js'
import Doughnut from '@/views/chart/Doughnut'
import {required, email} from '@/helpers/validators'

export default {
  name: 'EvaluationDetail',
  components: {Doughnut},
  filters: {
    age: function (birth) {
      return moment().diff(moment(birth, 'YYYY-MM-DD'), 'years')
    },
    monthsToAge: function (months){
      const years = parseInt( months/12 )
      const ymonths = parseInt( months%12 )
      return years + ' años ' + ymonths + ' meses'
    },
    twoDecimal: function (number){
      return (Math.round(number * 100)/100).toFixed(2)
    }
  },
  data () {
    return {
      needValidation: false,
			selTime: 40,
      waistHeight: [this.$i18n.t('severeThinness'),this.$i18n.t('slightThinness'),this.$i18n.t('normalWeight'),this.$i18n.t('overweight'),this.$i18n.t('highOverweight'),this.$i18n.t('morbidObesity')],
      referencePGrasa: [
        {value: this.$i18n.t('veryLow'), reference: '< 5', calcValue: 5},
        {value: this.$i18n.t('low'), reference: '5 - 10 ', calcValue: 10},
        {value: this.$i18n.t('optimum'), reference: '11 - 15', calcValue: 15},
        {value: this.$i18n.t('moderate'), reference: '16 - 20', calcValue: 20},
        {value: this.$i18n.t('highModerate'), reference: '21 - 25', calcValue: 25},
        {value: this.$i18n.t('high'), reference: '26 - 30', calcValue: 30},
        {value: this.$i18n.t('veryHigh'), reference: '31 - 35 ', calcValue: 35},
        {value: this.$i18n.t('extremelyHigh'), reference: '35 <', calcValue: 999999}
      ],
      referenceScoring: [
        {nentrada: this.$i18n.t('risk'), value: '1.1 - 7', calcValue: 7, nentreno: this.$i18n.t('basic')},
        {nentrada: this.$i18n.t('normal'), value: '-1.5 - 1', calcValue: 1.1, nentreno: this.$i18n.t('medium')},
        {nentrada: this.$i18n.t('veryGood'), value: '-8 - -1.6', calcValue: -1.5, nentreno: this.$i18n.t('advance')},
      ],
      // referenceScoring: [
      //   {nentrada: 'Alto Riesgo', value: '3.6 - 7', calcValue: 7, nentreno: 'Basico I'},
      //   {nentrada: 'Riesgo', value: '2.6 - 3.5', calcValue: 3.5, nentreno: 'Basico II'},
      //   {nentrada: 'Sedentario', value: '1.1 - 2.5', calcValue: 2.5  , nentreno: 'Basico III'},
      //   {nentrada: 'Normal Sedentario', value: '0.3 - 1', calcValue:  1, nentreno: 'Intermedio I'},
      //   {nentrada: 'Normal ', value: '-0.5 - 0.2', calcValue: 0.2, nentreno: 'Intermedio II'},
      //   {nentrada: 'En Forma', value: '-1.5 - -0.6', calcValue: -0.6, nentreno: 'Intermedio III'},
      //   {nentrada: 'Atlético', value: '-2.9 - -1.6', calcValue: -1.6, nentreno: 'Avanzado I'},
      //   {nentrada: 'Deportivo', value: '-3.9 - -3', calcValue: -3, nentreno: 'AvanzadoII'},
      //   {nentrada: 'Elite', value: '-8 - -4', calcValue: -4, nentreno: 'Avanzado III'},
      // ],
      loadingEvaluation: false,
      evaluationData: null,
      sendevaluationEmail: '',
      loading: false,
			scoringNentrenaRef: {
				n1: 10,
				n2: 80,
				n3: 10,
			}
    }
  },
  mounted(){
    this.getEvaluation()
  },
  methods: {
    required, email,
		setPercentEntrena(){
			console.log("AAS: ", this.evaluationData.evaluacion.nentrena)
			if(this.evaluationData.evaluacion.nentrena == "Básico"){
				this.scoringNentrenaRef = {
					n1: 10,
					n2: 80,
					n3: 10,
				}
			}
			else if(this.evaluationData.evaluacion.nentrena == "Intermedio"){
				this.scoringNentrenaRef = {
					n1: 10,
					n2: 60,
					n3: 30,
				}
			}
			else if(this.evaluationData.evaluacion.nentrena == "Avanzado"){
				this.scoringNentrenaRef = {
					n1: 10,
					n2: 45,
					n3: 45,
				}
			}
		},
    setClassForScoring(index, value){
      const referenceScoring = [...this.referenceScoring]
      const found = referenceScoring.reverse().findIndex((item) => {
        return item.calcValue > value
      })
      if(index == referenceScoring.length - 1 - found){
        if(index == 0){
          return 'bg-danger text-white'
        }
        else if(index == 1){
          return 'bg-warning'
        }
        else if(index == 2){
          return 'bg-success'
        }
        // else if(index == 1){
        //   return 'bg-orange text-white'
        // }
        // else if(index == 2){
        //   return 'bg-warning'
        // }
        // else if(index == 3){
        //   return 'bg-success'
        // }
        // else if(index == 4 || index == 5){
        //   return 'bg-light-blue text-white'
        // }
        // else if(index >= 6){
        //   return 'bg-primary text-white'
        // }
      }
    },
    setClassForPGrasa(index, value){
      let classname = ''
      if(index%2 == 0){ classname = ' bg-secondary' }
      const found = this.referencePGrasa.findIndex((item) => {
        return value <= item.calcValue
      })
      if(found == index){
        if(index == 1 || index == 3 || index == 4){
          classname = ' bg-warning'
        }
        else if(index != 2){
          classname = ' bg-danger'
        }
        else{
          classname = ' bg-success'
        }
      }
      return classname
    },
    setClassForWaisHeight(index, value){
      let classname = ''
      if(index%2 == 0){ classname = ' bg-secondary' }
      if(index == value){
        if(index == 1 || index == 3){
          classname = ' bg-warning'
        }
        else if(index == 0 || index == 4 || index == 5){
          classname = ' bg-danger'
        }
        else{
          classname = ' bg-success'
        }
      }
      return classname
    },
    async getEvaluation(){
      if(this.$route.params.evaluationID){
        this.loadingEvaluation = true
        const id = this.$route.params.evaluationID
        const evaluation = await getEvaluation({id})
        if(evaluation.evaluacion){
          this.evaluationData = evaluation
					this.sendevaluationEmail = evaluation.evaluado.email
					this.setPercentEntrena()
        }
        this.loadingEvaluation = false
      }
      else{
        this.$router.push({ name: 'Evaluaciones' })
      }
    },
    async sendEvaluation(){
      this.needValidation = true
      if(this.email(this.sendevaluationEmail)){
        this.loading = true
        try{
          const data = {
            email: this.sendevaluationEmail,
            id: this.$route.params.evaluationID,
            lang: localStorage.getItem('lang'),
          }
          const sent = await sendEvaluationByEmail(data)
          this.$store.commit('addToast', {
            color: 'success',
            header: 'Evaluación enviada',
            message: 'Se ha enviado la evaluación al correo ingresado.'
          })
        }
        catch(e){
          this.$store.commit('addToast', {
            color: 'warning',
            header: 'Error',
            message: 'Hubo un error el enviar la evaluación, por favor intenta nuevamente.'
          })
        }
        this.loading = false
      }
      else{
        this.$store.commit('addToast', {
          color: 'warning',
          header: 'Error',
          message: 'Por favor verifica el correo ingresado.'
        })
      }
    }
  }
}
</script>
<style scoped>
	p{
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.color-indicator{
		width: 20px;
		height: 20px;;
	}
</style>