<template>
  <CChartDoughnut
    :datasets="datasets"
    :labels="labels"
    :options="{
      responsive: true,
      maintainAspectRatio: false,
    }"
    style="height: 250px"
  />
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'ChartDoughnut',
  props: {
    datasets: {
      type: Array,
      default: {}
    },
    labels: {
      type: Array,
      default: []
    }
  },
  components: { CChartDoughnut },
}
</script>
